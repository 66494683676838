module.exports = {
    'fr': {
        texte1: "Thibault",
        texte2: "Schmitt",
        texte3: "Développeur",
        texte4: "web",
        texte5: "Bienvenue sur mon site web ! J'espère que l'expérience vous plaira."

    },
    'eng': {
        texte1: "Thibault",
        texte2: "Schmitt",
        texte3: "Web",
        texte4: "Developer",
        texte5: "Welcome to my website! I hope you enjoy the experience."
    }
}